import cx from 'classnames'
import { type Variants, motion } from 'motion/react'
import { type ReactNode, useState } from 'react'

import Icon from '@components/icon'

type AccordionVariant = 'ui' | 'content'

interface AccordionProps {
  id: string
  title?: ReactNode
  variant?: AccordionVariant
  className?: string
  innerClassName?: string
  children?: ReactNode
}

const accordionContentVariants: Variants = {
  open: {
    opacity: 1,
    height: 'auto',
  },
  closed: {
    opacity: 0,
    height: 0,
  },
}

const Accordion = ({
  id,
  title,
  variant = 'ui',
  className,
  innerClassName,
  children,
}: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div
      className={cx(
        'border-current',
        {
          'border-t': variant === 'ui',
          'border border-b-0 last-of-type:border-b': variant === 'content',
        },
        className
      )}
    >
      <button
        onClick={() => setIsOpen((isOpen) => !isOpen)}
        aria-expanded={isOpen}
        aria-controls={`accordion-${id}`}
        className={cx(
          'text-left flex justify-between items-center w-full bg-transparent px-3 py-3.5'
        )}
      >
        <h3>{title}</h3>
        <div className="w-3.5 h-3.5 flex items-center justify-center">
          <Icon
            id={`accordion-icon-${id}`}
            name={variant === 'content' ? 'Plus' : 'ChevronDown'}
            className={cx(
              'transition-transform duration-300 ease-custom-1 text-current h-full',
              {
                '[&>line:last-of-type]:transition-transform [&>line]:origin-center':
                  variant === 'content',
                transform: isOpen,
                'rotate-180': isOpen && variant !== 'content',
                '[&>line:last-of-type]:rotate-90':
                  isOpen && variant == 'content',
              }
            )}
          />
        </div>
      </button>

      <motion.div
        id={`accordion-${id}`}
        initial="closed"
        animate={isOpen ? 'open' : 'closed'}
        exit="closed"
        variants={accordionContentVariants}
        transition={{
          duration: 0.5,
          ease: [0.19, 1.0, 0.22, 1.0],
        }}
        className="overflow-hidden"
      >
        <div
          className={cx(
            { 'border-current border-t px-3 py-3.5': variant == 'content' },
            innerClassName
          )}
        >
          {children}
        </div>
      </motion.div>
    </div>
  )
}

export default Accordion
