import { type PortableTextMarkComponentProps } from '@portabletext/react'
import cx from 'classnames'

import { type SanityBlockColoredText } from '@data/sanity/queries/types/content'

type ColoredTextValue = SanityBlockColoredText

const ColoredText = ({
  value,
  children,
}: PortableTextMarkComponentProps<ColoredTextValue>) => {
  if (!value) {
    return null
  }

  return (
    <span
      className={cx({
        'opacity-30': value.faded,
      })}
    >
      {children}
    </span>
  )
}

export default ColoredText
