import NextLink from 'next/link'
import { type HTMLAttributes, type ReactNode } from 'react'

type SimpleLinkProps = Pick<
  HTMLAttributes<HTMLAnchorElement>,
  'onClick' | 'onBeforeInput' | 'tabIndex'
> & {
  href: string
  className?: string
  children?: ReactNode
}

const SimpleLink = ({
  href,
  onClick,
  onBeforeInput,
  tabIndex,
  children,
  className,
}: SimpleLinkProps) => (
  <NextLink
    role="link"
    href={href}
    tabIndex={tabIndex}
    onClick={onClick}
    onBeforeInput={onBeforeInput}
    className={className}
  >
    {children}
  </NextLink>
)

export default SimpleLink
